import { DESIGN_ASSETS_URL } from '../../../const.js'
import { SmartLink } from '../../SmartLink/SmartLink.jsx'

export const LogoSvg = ({ theme = 'primary' }) => {
  if (theme === 'inverse') {
    return <img src={`${DESIGN_ASSETS_URL}/logos/nav-logo-on-dark.svg`} alt="Nav Logo" />
  }

  return <img src={`${DESIGN_ASSETS_URL}/logos/nav-logo-on-light.svg`} alt="Nav Logo" />
}

export const Logo = ({
  theme = 'primary',
  size = 'small',
}: {
  theme?: 'primary' | 'inverse'
  size?: 'small' | 'large'
}) => {
  const sizeClasses = {
    small: 'w-[78.75px] h-[24px]',
    large: 'w-[91.88px] h-[28px]',
  }
  return (
    <div className={`${sizeClasses[size]}`}>
      <LogoSvg theme={theme} />
    </div>
  )
}
export const LinkedLogo = ({
  href = '/',
  theme = 'primary',
  size = 'small',
}: {
  theme?: 'primary' | 'inverse'
  size?: 'small' | 'large'
  href?: string
}) => {
  return (
    <SmartLink to={href} className="inline-flex items-center" data-testid="header:nav-logo">
      <Logo size={size} theme={theme} />
    </SmartLink>
  )
}
