export type { PayloadTypes } from '@cms/index.js'
import { useLivePreview } from '@payloadcms/live-preview-react'
import { initGraphQLTada } from 'gql.tada'
import type { DocumentNode } from 'graphql'

import { introspection } from '../../../../types/cms-graphql-env.js'
import { clientEnv, env } from '../lib/env.js'
import { GqlQueryFields, gqlRequest, GqlRequestProps } from './gql-request.js'
import { isServer } from './is-server.js'

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    DateTime: string
  }
}>()

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada'
export { readFragment } from 'gql.tada'

const CMS_GRAPHQL_API_URL = isServer
  ? `${env.NAV_CMS_URL}/api/graphql`
  : `${clientEnv.NAV_WEBSITE_SERVER_URL}/api/cms/graphql`

const clientCmsApiUrl = '/api/cms'

export const whereDraftStatus = (draft: boolean | undefined) => ({
  ...(!draft &&
    ({
      _status: {
        equals: 'published',
      },
    } as const)),
})

export const cmsGqlRequest = async <T extends DocumentNode>(
  props: GqlQueryFields<T> & Omit<GqlRequestProps, 'url'> & { manualErrorHandling?: boolean },
) => {
  const res = await gqlRequest({
    url: CMS_GRAPHQL_API_URL,
    ...props,
  })

  if (!props.manualErrorHandling && res.errors) {
    throw new Error(res.errors[0]?.message ?? 'Error fetching graphql')
  }

  return res
}

export const useCmsPreview = <T>(options: Pick<Parameters<typeof useLivePreview<T>>[0], 'initialData' | 'depth'>) => {
  return useLivePreview({
    apiRoute: clientCmsApiUrl,
    serverURL: clientEnv.NAV_CMS_PUBLIC_URL,
    ...options,
  })
}
