import { graphql } from '../../../lib/cms.js'
import { resolveHrefFragment } from '../../../lib/resolveHref.js'
import { listItemLinkFragment } from './ListItem.jsx'

export const headerNavbarFragment = graphql(
  /* GraphQL */ `
    fragment headerNavbarFragment on CmsHeader_Nav {
      type
      title
      submenu {
        categories {
          name
          items {
            title
            description
            link {
              ...listItemLinkFragment
            }
          }
        }
      }
      link {
        ...listItemLinkFragment
        ...resolveHrefFragment
      }
    }
  `,
  [listItemLinkFragment, resolveHrefFragment],
)
