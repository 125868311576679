import { Icon } from '@navinc/base-react-components/wayfinder'
import { forwardRef, ReactNode } from 'react'

import { FragmentOf, graphql, readFragment } from '../../../lib/cms.js'
import { cmsLinkFragment, CMSLinkFromFragmentUsingChildren } from '../../Link/index.jsx'
import { cn } from '@navinc/base-react-components'

export const listItemLinkFragment = graphql(
  /* GraphQL */ `
    fragment listItemLinkFragment on CmsLink {
      ...cmsLinkFragment
      iconBefore
      iconAfter
    }
  `,
  [cmsLinkFragment],
)

export const ListItem = forwardRef<
  unknown,
  {
    description?: ReactNode
    title: ReactNode
    onClick?: () => void
    link: FragmentOf<typeof listItemLinkFragment> | null
    className?: string
  }
>(({ description, title, onClick, link: linkFragment, className }, _ref) => {
  if (!linkFragment) {
    return null
  }

  const { iconBefore, iconAfter, ...link } = readFragment(listItemLinkFragment, linkFragment)

  return (
    <CMSLinkFromFragmentUsingChildren
      data={link}
      className={cn('flex min-w-[200px] flex-col items-start py-150 text-left', className)}
      iconBefore={null}
      iconAfter={null}
      onClick={onClick}
      defaultVariant="plain"
    >
      <span className="body2-emphasized flex items-center">
        {iconBefore && <Icon className="mr-50" name={iconBefore} />} {title}
        {iconAfter && <Icon className="ml-50" name={iconAfter} />}
      </span>
      {description && <span className="caption1 text-onSurfaceVariant">{description}</span>}
    </CMSLinkFromFragmentUsingChildren>
  )
})
