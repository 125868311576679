import { Button } from '@navinc/base-react-components/wayfinder'
import * as Accordion from '@radix-ui/react-accordion'
import * as Dialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { ReactNode, useState } from 'react'

import { FragmentOf, readFragment } from '../../../lib/cms.js'
import { NavbarDisplay } from './index.js'
import { ListItem } from './ListItem.js'
import { LinkedLogo } from './Logo.js'
import { headerNavbarFragment } from './menu-fragment.js'
import classes from './vertical-menu.module.scss'

interface VerticalMenuProps {
  menuItems: FragmentOf<typeof headerNavbarFragment>[]
  display?: NavbarDisplay
}

const AccordionTrigger = ({ children, className }: { children: ReactNode; className?: string }) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger className={classNames('AccordionTrigger', className)}>{children}</Accordion.Trigger>
  </Accordion.Header>
)

const AccordionContent = ({ children, className, ...props }: { children: ReactNode; className?: string }) => (
  <Accordion.Content className={classNames('AccordionContent', className)} {...props}>
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
)

const CloseSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13.4L7.1 18.3C6.91667 18.4833 6.68333 18.575 6.4 18.575C6.11667 18.575 5.88333 18.4833 5.7 18.3C5.51667 18.1167 5.425 17.8833 5.425 17.6C5.425 17.3167 5.51667 17.0833 5.7 16.9L10.6 12L5.7 7.1C5.51667 6.91667 5.425 6.68333 5.425 6.4C5.425 6.11667 5.51667 5.88333 5.7 5.7C5.88333 5.51667 6.11667 5.425 6.4 5.425C6.68333 5.425 6.91667 5.51667 7.1 5.7L12 10.6L16.9 5.7C17.0833 5.51667 17.3167 5.425 17.6 5.425C17.8833 5.425 18.1167 5.51667 18.3 5.7C18.4833 5.88333 18.575 6.11667 18.575 6.4C18.575 6.68333 18.4833 6.91667 18.3 7.1L13.4 12L18.3 16.9C18.4833 17.0833 18.575 17.3167 18.575 17.6C18.575 17.8833 18.4833 18.1167 18.3 18.3C18.1167 18.4833 17.8833 18.575 17.6 18.575C17.3167 18.575 17.0833 18.4833 16.9 18.3L12 13.4Z"
      fill="currentColor"
    />
  </svg>
)

const HamburgerSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 18C3.71667 18 3.475 17.9083 3.275 17.725C3.09167 17.525 3 17.2833 3 17C3 16.7167 3.09167 16.4833 3.275 16.3C3.475 16.1 3.71667 16 4 16H20C20.2833 16 20.5167 16.1 20.7 16.3C20.9 16.4833 21 16.7167 21 17C21 17.2833 20.9 17.525 20.7 17.725C20.5167 17.9083 20.2833 18 20 18H4ZM4 13C3.71667 13 3.475 12.9083 3.275 12.725C3.09167 12.525 3 12.2833 3 12C3 11.7167 3.09167 11.4833 3.275 11.3C3.475 11.1 3.71667 11 4 11H20C20.2833 11 20.5167 11.1 20.7 11.3C20.9 11.4833 21 11.7167 21 12C21 12.2833 20.9 12.525 20.7 12.725C20.5167 12.9083 20.2833 13 20 13H4ZM4 8C3.71667 8 3.475 7.90833 3.275 7.725C3.09167 7.525 3 7.28333 3 7C3 6.71667 3.09167 6.48333 3.275 6.3C3.475 6.1 3.71667 6 4 6H20C20.2833 6 20.5167 6.1 20.7 6.3C20.9 6.48333 21 6.71667 21 7C21 7.28333 20.9 7.525 20.7 7.725C20.5167 7.90833 20.2833 8 20 8H4Z"
      fill="currentColor"
    />
  </svg>
)

const VerticalMenuListItem = ({ children }: { children: ReactNode }) => {
  return <div className="border-b border-outlineVariant">{children}</div>
}

export const VerticalMenuItems = (props: {
  setOpen: (open: boolean) => void
  menuItems: FragmentOf<typeof headerNavbarFragment>[]
}) => {
  const menuItems = readFragment(headerNavbarFragment, props.menuItems)
  const setOpen = props.setOpen

  return (
    <div className="px-200 py-250">
      <LinkedLogo theme="primary" size="small" />

      <div className="flex flex-col gap-200 py-300">
        <Button variant="primary" density="loose" className="w-full" asChild>
          <a href="https://app.nav.com/registration" data-testid="dialog:create-account">
            Create account
          </a>
        </Button>
        <Button className="w-full" density="loose" asChild>
          <a href="https://app.nav.com/login" data-testid="dialog:login">
            Log in
          </a>
        </Button>
      </div>
      <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
        {menuItems.map((menuItem, menuItemKey) => {
          if (menuItem.type === 'link') {
            return (
              <VerticalMenuListItem key={menuItemKey}>
                <ListItem
                  link={menuItem.link}
                  title={menuItem.title}
                  onClick={() => setOpen(false)}
                  className="py-200"
                />
              </VerticalMenuListItem>
            )
          }
          return (
            <VerticalMenuListItem key={menuItemKey}>
              <Accordion.Item className="AccordionItem w-full" value={menuItem.title ?? 'TODO'}>
                <AccordionTrigger className="group/accordion-trigger flex w-full items-center justify-between py-200">
                  <span className="body2  body2-emphasized text-onSurfaceVariant">{menuItem.title}</span>
                  <div className="transition-[transform_300ms_cubic-bezier(0.87,0,0.13,1) group-data-[state=open]/accordion-trigger:rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                      <rect width="15" height="15" fill="white" fillOpacity="0.01" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                        fill="#5E5D67"
                      />
                    </svg>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="NavigationMenuSubmenuGroupWrapper pb-150">
                    {menuItem.submenu?.categories?.map((category, categoryKey) => {
                      return (
                        <div className="NavigationMenuSubmenuGroup" key={categoryKey}>
                          <div className="caption2-uppercase my-150">{category.name}</div>
                          <ul className="NavigationMenuCategoryList">
                            {category.items?.map((submenuItem, submenuItemIndex) => {
                              return (
                                <li key={submenuItemIndex}>
                                  <ListItem
                                    title={submenuItem.title}
                                    description={submenuItem.description}
                                    link={submenuItem.link}
                                    onClick={() => setOpen(false)}
                                  />
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                </AccordionContent>
              </Accordion.Item>
            </VerticalMenuListItem>
          )
        })}
      </Accordion.Root>
      <VerticalMenuListItem>
        <Button className="w-full" variant="plain" density="loose" asChild>
          <a href="https://help.nav.com" data-testid="dialog:support" className="flex justify-start py-200 text-left">
            <span className="body2 body2-emphasized text-onSurfaceVariant">Support</span>
          </a>
        </Button>
      </VerticalMenuListItem>
    </div>
  )
}

export const VerticalMenu = ({ menuItems, display = 'solid' }: VerticalMenuProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className="NavigationMenuVertical relative z-[1000] flex items-center justify-between px-200 py-100"
      data-testid="header:navigation:vertical"
    >
      <LinkedLogo theme={display === 'solid' ? 'primary' : 'inverse'} size="small" />
      <nav>
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Trigger asChild>
            <button className="p-150">
              <span className={`${display === 'solid' ? 'text-onSurface' : 'text-inverseOnSurface'}`}>
                <HamburgerSvg />
              </span>
            </button>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className={`${classes.DialogOverlay} fixed inset-0 bg-inverseSurface md:hidden`} />
            <Dialog.Content
              className={`${classes.DialogContent} fixed left-1/2 top-1/2 z-[2000] h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-0 bg-surfaceBright focus:outline-none md:hidden`}
            >
              <div className="flex size-full flex-col overflow-scroll">
                <Dialog.Close asChild>
                  <button
                    className="absolute right-200 top-100 z-[2050] inline-flex size-600 items-center justify-center"
                    aria-label="Close"
                  >
                    <CloseSvg />
                  </button>
                </Dialog.Close>
                <VerticalMenuItems menuItems={menuItems} setOpen={setOpen} />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </nav>
    </div>
  )
}
