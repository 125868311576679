const pagesCollection = 'pages'

export const pageReferenceFieldsGqlString = /* GraphQL */ `
  relationTo
  value {
    ... on CmsPage {
      slug
      parent {
        slug
      }
    }
    ... on CmsMarketplacePage {
      slug
      parent {
        slug
      }
    }
    ... on CmsGuide {
      slug
    }
    ... on CmsArticle {
      slug
    }
  }
`

export const pagePathname = (
  relationTo: string | null | undefined,
  doc: { slug: string | null; parent?: { slug: string | null } | null } | null | undefined,
) => {
  const slug = doc?.slug
  const parentSlug = doc?.parent?.slug
  const pathname =
    relationTo === pagesCollection ? `/${parentSlug ? `${parentSlug}/` : ''}${slug}` : `/${relationTo}/${slug}`
  return relationTo === pagesCollection && slug === 'home' ? '/' : pathname
}
