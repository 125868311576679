import { cn } from '@navinc/base-react-components'
import { FC } from 'react'

import { Children, serialize } from './serialize.js'

export const RichText: FC<{ content?: Children; className?: string }> = ({ content, className }) => {
  if (!content) {
    return null
  }

  return <div className={cn('first:mt-0 last:mb-0 w-full', className)}>{serialize(content)}</div>
}
