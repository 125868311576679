import { stringify } from 'qs'

import { FragmentOf, graphql, readFragment } from './cms.js'
import { pagePathname, pageReferenceFieldsGqlString } from './page-pathname.js'

export const resolveHrefFragment = graphql(/* GraphQL */ `
  fragment resolveHrefFragment on CmsLink {
    type
    url
    query {
      key
      value
    }
    targetId
    reference {
      ${pageReferenceFieldsGqlString}
    }
  }
`)

export const resolveHref = (linkFragment: FragmentOf<typeof resolveHrefFragment> | null): string => {
  const link = readFragment(resolveHrefFragment, linkFragment)

  if (!link) {
    return ''
  }

  if (link.type === 'reference' && typeof link.reference?.value !== 'number') {
    const referenceValue = link.reference?.value

    if (!referenceValue || !referenceValue.slug) {
      return ''
    }

    let pathname = pagePathname(link.reference?.relationTo, referenceValue)

    if (link.targetId) {
      pathname = `${pathname}#${link.targetId}`
    }

    if (link.query && link.query.length) {
      const queryObj = link.query.reduce<{ [key: string]: string }>((final, item) => {
        if (item.key !== null && item.value !== null) {
          final[item.key] = item.value
        }
        return final
      }, {})

      pathname = `${pathname}?${stringify(queryObj)}`
    }

    return pathname
  }

  if (link.type === 'custom' && link.url) {
    return link.url
  }

  return `/`
}
