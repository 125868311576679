import { cn } from '@navinc/base-react-components'
import { FragmentOf } from 'gql.tada'

import { graphql } from '../../lib/cms.js'
import { RichText } from '../RichText/index.js'
import { Children } from '../RichText/serialize.jsx'
import classes from './disclaimer.module.scss'

export const cmsDisclaimerFragment = graphql(/* GraphQL */ `
  fragment cmsDisclaimerFragment on CmsDisclaimersArray @_unmask {
    text {
      ... on CmsLegal {
        richText
      }
    }
    align
    includeAsterick
    number
  }
`)

export type DisclaimerFragment = FragmentOf<typeof cmsDisclaimerFragment>

export const Disclaimer = ({ text, number, includeAsterick, align }: DisclaimerFragment) => {
  const alignmentClass = {
    left: 'items-start text-left',
    center: 'items-center text-center',
    right: 'items-end text-right',
  }[align ?? 'left']
  return (
    <div className={cn(classes.disclaimer, 'caption2 flex flex-col items-start', alignmentClass)}>
      <div className="max-w-[800px] flex">
        {number && <sup className="top-0 leading-none">{number}</sup>}
        {includeAsterick && <sup className="top-0 leading-none">*</sup>}
        <RichText content={text?.richText as Children} />
      </div>
    </div>
  )
}
