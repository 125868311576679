import { Link } from '@remix-run/react'
import { ComponentProps } from 'react'

export const SmartLink = ({ to, children, ...props }: ComponentProps<typeof Link>) => {
  if (typeof to !== 'string' || to.startsWith('/') || to.startsWith('#')) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <a href={to} {...props}>
      {children}
    </a>
  )
}
