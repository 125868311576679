import { cn } from '@navinc/base-react-components'
import { CSSProperties, ReactNode } from 'react'

interface Props {
  className?: string
  children: ReactNode
  style?: CSSProperties
}

export const Gutter = ({ className, children, style = {} }: Props) => {
  return (
    <div style={style} className={cn('max-w-[1440px] w-full my-0 mx-auto', className)}>
      {children}
    </div>
  )
}
