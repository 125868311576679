import { cmsDisclaimerFragment } from '../_components/Disclaimer/index.jsx'
import { graphql } from '../lib/cms.js'

export const cmsCustomFooterFragment = graphql(
  /* GraphQL */ `
    fragment cmsCustomFooterFragment on CmsCustomFooter {
      isHidden
      disclaimers {
        ...cmsDisclaimerFragment
      }
    }
  `,
  [cmsDisclaimerFragment],
)
