import { InferComponentProps } from '@navinc/base-react-components'
import { Button as WFButton } from '@navinc/base-react-components/wayfinder'
import { CSSProperties, ReactNode } from 'react'

import { SmartLink } from '../SmartLink/SmartLink.jsx'

export type Density = 'base' | 'standard' | 'tight' | 'loose'

export type Props = {
  label?: string | null
  variant?: InferComponentProps<typeof WFButton>['variant'] | null
  onClick?: () => void
  href?: string
  newTab?: boolean | null | undefined
  className?: string
  type?: 'submit' | 'button'
  disabled?: boolean
  children?: ReactNode
  density?: Density
  'data-testid'?: string
  style?: CSSProperties
}

export const Button = ({
  newTab,
  href,
  variant,
  className,
  onClick,
  type = 'button',
  disabled,
  children,
  density = 'standard',
  'data-testid': dataTestid,
  style = {},
}: Props) => {
  let elChildren = children

  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}

  if (href) {
    elChildren = (
      <SmartLink to={href || ''} {...newTabProps}>
        {children}
      </SmartLink>
    )
  }

  return (
    <WFButton
      variant={variant ?? 'default'}
      density={density as any} // eslint-disable-line @typescript-eslint/no-explicit-any -- Density not exposed by Wayfinder
      asChild={!!href}
      onClick={onClick}
      className={className}
      type={type}
      disabled={disabled}
      data-testid={dataTestid}
      style={style}
    >
      {elChildren}
    </WFButton>
  )
}
