import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { cn } from '@navinc/base-react-components'
import { ReactNode } from 'react'

export const cmsBlockSectionFragment = graphql(/* GraphQL */ `
  fragment cmsBlockSectionFragment on CmsSection {
    element {
      elemId
      className
    }
  }
`)

export const BlockSection = ({
  children,
  invertBackground,
  isNested,
  className,
  section,
}: {
  children: ReactNode
  invertBackground?: boolean | null
  isNested?: boolean
  className?: string
  section?: FragmentOf<typeof cmsBlockSectionFragment> | null
}) => {
  const backgroundClasses = invertBackground ? 'bg-primeContainer' : 'bg-surfaceBright'
  const textClasses = invertBackground ? 'text-inverseOnSurface' : 'text-onSurface'

  const sectionData = readFragment(cmsBlockSectionFragment, section)
  const classNameFromCMS = sectionData?.element?.className ?? undefined
  const idFromCMS = sectionData?.element?.elemId ?? undefined
  return (
    <div
      id={idFromCMS}
      className={cn(
        classNameFromCMS,
        isNested ? 'pb-100' : 'px-200 py-400 md:py-1000',
        backgroundClasses,
        textClasses,
        className,
      )}
    >
      {children}
    </div>
  )
}
