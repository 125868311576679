import { cn, InferComponentProps } from '@navinc/base-react-components'
import { Icon } from '@navinc/base-react-components/wayfinder'
import { stringify } from 'qs'
import { CSSProperties } from 'react'

import { CmsMediaFragment } from '@/app/_graphql/media.js'
import { pagePathname } from '@/app/lib/page-pathname.js'

import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { Button } from '../Button/index.js'
import { SmartLink } from '../SmartLink/SmartLink.jsx'
import appleAppDownloadButton from './apple-app-download.webp'
import googleAppDownloadButton from './google-app-download.webp'

export const cmsLinkFragment = graphql(/* GraphQL */ `
  fragment cmsLinkFragment on CmsLink {
    label
    type
    variant
    newTab
    url
    query {
      key
      value
    }
    targetId
    reference {
      relationTo
      value {
        ... on CmsPage {
          parent {
            slug
          }
          slug
        }
        ... on CmsArticle {
          slug
        }
        ... on CmsGuide {
          slug
        }
        ... on CmsMarketplacePage {
          parent {
            slug
          }
          slug
        }
      }
    }
  }
`)

export const cmsLinkWithMetaFragment = graphql(
  /* GraphQL */ `
    fragment cmsLinkWithMetaFragment on CmsLink {
      label
      type
      variant
      newTab
      url
      query {
        key
        value
      }
      targetId
      reference {
        relationTo
        value {
          ... on CmsPage {
            slug
            parent {
              slug
            }
            meta {
              description
              title
              image {
                ...CmsMediaFragment
              }
            }
          }
          ... on CmsArticle {
            slug
            meta {
              description
              title
              image {
                ...CmsMediaFragment
              }
            }
          }
          ... on CmsGuide {
            slug
            meta {
              description
              title
              image {
                ...CmsMediaFragment
              }
            }
          }
          ... on CmsMarketplacePage {
            slug
            parent {
              slug
            }
            meta {
              description
              title
              image {
                ...CmsMediaFragment
              }
            }
          }
        }
      }
    }
  `,
  [CmsMediaFragment],
)

export type LinkFragment = FragmentOf<typeof cmsLinkFragment>
type ButtonProps = InferComponentProps<typeof Button>
type ButtonVariant = ButtonProps['variant'] | 'appleApp' | 'googleApp'

export type CMSLinkProps = {
  onClick?: HTMLButtonElement['click'] | HTMLAnchorElement['click']
  type?: 'reference' | 'custom' | null
  url?: string | null | undefined
  newTab?: boolean | null
  label?: string | null
  query?:
    | {
        key: string | null
        value: string | null
      }[]
    | null
  targetId?: string | null
  iconBefore?: string | null
  iconAfter?: string | null
  reference?: {
    relationTo: 'pages' | 'guides' | 'articles' | 'marketplace' | null
    value: {
      __typename?: 'CmsPage' | 'CmsArticle' | 'CmsGuide' | 'CmsMarketplacePage' | undefined
      slug: string | null
    } | null
  } | null
  'data-testid'?: string
  style?: CSSProperties
  defaultVariant?: ButtonVariant
  variant?: ButtonVariant
} & Partial<Pick<ButtonProps, 'children' | 'density' | 'className'>>

export const CMSLink = ({
  type,
  url,
  newTab,
  reference,
  label,
  defaultVariant = 'default',
  variant,
  density = 'standard',
  children,
  className,
  query,
  targetId,
  iconBefore,
  iconAfter,
  style = {},
  onClick,
  'data-testid': dataTestid,
}: CMSLinkProps) => {
  variant ??= defaultVariant

  let href =
    type === 'reference' && typeof reference?.value === 'object' && reference.value?.slug
      ? pagePathname(reference.relationTo, reference.value)
      : url

  if (!href) return null

  if (type === 'reference') {
    if (targetId) {
      href = `${href}#${targetId}`
    }

    if (query && query.length) {
      const queryObj = query.reduce<{ [key: string]: string }>((final, item) => {
        if (item.key !== null && item.value !== null) {
          final[item.key] = item.value
        }
        return final
      }, {})

      href = `${href}?${stringify(queryObj)}`
    }
  }

  if (variant === 'appleApp') {
    return (
      <SmartLink
        to={href}
        title="Download the Nav Mobile App from the Apple App Store"
        aria-label="Download the Nav Mobile App from the Apple App Store"
        className={cn(className, 'inline-flex h-600 w-[145px]')}
        style={style}
        onClick={onClick}
      >
        <img src={appleAppDownloadButton} alt="Apple App Store download button" />
      </SmartLink>
    )
  }

  if (variant === 'googleApp') {
    return (
      <SmartLink
        to={href}
        title="Download the Nav Mobile App from the Google Play Store"
        aria-label="Download the Nav Mobile App from the Google Play Store"
        className={cn(className, 'inline-flex h-600 w-[163px]')}
        onClick={onClick}
        style={style}
      >
        <img src={googleAppDownloadButton} alt="Google Play Store download button" />
      </SmartLink>
    )
  }

  return (
    <Button
      className={className}
      newTab={newTab}
      href={href}
      variant={variant}
      label={label}
      density={density}
      onClick={onClick}
      data-testid={dataTestid}
      style={style}
    >
      <>
        {iconBefore && <Icon className="mr-50" name={iconBefore} />}
        {children}
        {iconAfter && <Icon className="ml-50" name={iconAfter} />}
      </>
    </Button>
  )
}

export const CMSLinkFromFragment = ({
  data,
  ...props
}: {
  data: LinkFragment
} & Partial<CMSLinkProps>) => {
  const readData = readFragment(cmsLinkFragment, data)
  return (
    <CMSLink {...readData} {...props}>
      {readData.label}
    </CMSLink>
  )
}

export const CMSLinkFromFragmentUsingChildren = ({
  data,
  ...props
}: {
  data: LinkFragment
} & Partial<CMSLinkProps>) => {
  const readData = readFragment(cmsLinkFragment, data)
  return (
    <CMSLink {...readData} {...props}>
      {props.children}
    </CMSLink>
  )
}
