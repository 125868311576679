import { useRouteLoaderData } from '@remix-run/react'
import { ReactNode } from 'react'

import { BlockSection } from '../_components/BlockSection/index.jsx'
import { Button } from '../_components/Button/index.js'
import { Footer } from '../_components/Footer/index.jsx'
import { Gutter } from '../_components/Gutter/index.js'
import { Header } from '../_components/Header/index.jsx'
import { loader as rootLoader } from '../root.js'

type ErrorPageProps = {
  title?: string
  message?: string
  actions?: ReactNode
}

export const ErrorPage = ({ title, message, actions }: ErrorPageProps) => {
  const { header, footer } = useRouteLoaderData<typeof rootLoader>('root') ?? {}

  return (
    <>
      <Header header={header ?? null} />
      <div className="w-full grow">
        <BlockSection>
          <Gutter className="align-center my-1000 flex flex-col justify-center gap-400 rounded-200 bg-surfaceDim py-[128px]  text-center text-onSurface">
            <div className="align-center flex flex-col gap-200 self-stretch">
              <h1 className="display2-emphasized">{title || 'There was an error loading this page'}</h1>
              <p className="title3">{message || 'Please try again later or contact us if this keeps occurring'}</p>
            </div>
            <div className="flex justify-center gap-100">
              {actions ?? (
                <Button href="/" variant="primary">
                  Return home
                </Button>
              )}
            </div>
          </Gutter>
        </BlockSection>
      </div>
      <Footer footer={footer ?? null} />
    </>
  )
}
