import { cmsCustomFooterFragment } from '@/app/_graphql/customFooter.js'

import { FragmentOf, graphql, readFragment } from '../../lib/cms.js'
import { resolveHref, resolveHrefFragment } from '../../lib/resolveHref.js'
import { cmsDisclaimerFragment, Disclaimer } from '../Disclaimer/index.jsx'
import { Gutter } from '../Gutter/index.jsx'
import { Logo } from '../Header/Navbar/Logo.js'
import { SmartLink } from '../SmartLink/SmartLink.jsx'

export const footerFragment = graphql(
  /* GraphQL */ `
    fragment footerFragment on CmsFooter {
      footerColumns {
        linkColumns {
          columnTitle
          links {
            link {
              label
              ...resolveHrefFragment
            }
          }
        }
      }
      termsLinks {
        link {
          label
          ...resolveHrefFragment
        }
      }
      copyrightText
      disclaimers {
        ...cmsDisclaimerFragment
      }
    }
  `,
  [resolveHrefFragment, cmsDisclaimerFragment],
)

interface FooterComponentProps {
  footer: FragmentOf<typeof footerFragment> | null
  customFooter?: FragmentOf<typeof cmsCustomFooterFragment> | null
}

export const Footer = (props: FooterComponentProps) => {
  const footer = readFragment(footerFragment, props.footer)
  const customFooter = readFragment(cmsCustomFooterFragment, props.customFooter)

  const footerColumns = footer?.footerColumns ?? []
  const termsLinks = footer?.termsLinks ?? []
  const copyrightText = footer?.copyrightText
  const disclaimers = customFooter?.disclaimers?.length ? customFooter.disclaimers : footer?.disclaimers

  if (customFooter?.isHidden) {
    return null
  }
  return (
    <footer className="flex flex-col gap-800 bg-primeContainer px-200 py-[36px] md:p-800">
      <Gutter className="w-full">
        <div className="Wrapper flex flex-col items-start gap-300  pb-800 tablet:flex-row tablet:gap-500">
          <div className="LogoWrapper item-start flex flex-col gap-[10] tablet:flex-1">
            <span className="block md:hidden">
              <Logo theme="inverse" size="small" />
            </span>
            <span className="hidden md:block">
              <Logo theme="inverse" size="large" />
            </span>
          </div>
          <div className="Links flex w-full justify-between gap-500 tablet:flex-1 tablet:items-start">
            {footerColumns.map((footerColumn, i) => {
              return (
                <div className="Link flex flex-1 flex-col gap-200 tablet:items-start" key={i}>
                  <p className="caption1-uppercase text-onSurfaceDim">{footerColumn.linkColumns?.columnTitle}</p>
                  <div className="flex flex-col items-start gap-100">
                    {footerColumn.linkColumns?.links?.map((link, i) => {
                      return (
                        <SmartLink
                          className="text-sm font-normal tracking-tight text-inverseOnSurface no-underline"
                          to={resolveHref(link.link)}
                          key={i}
                          type="footer"
                        >
                          {link.link?.label}
                        </SmartLink>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-col items-start justify-end gap-400">
          {!!footerColumns.length && !!termsLinks.length && <div className="h-px w-full bg-onSurfaceVariant" />}
          <div className="flex items-end justify-between self-stretch">
            <div className="flex w-full flex-col items-start justify-center gap-100">
              <div className="flex items-start gap-400">
                {termsLinks.map((link, i) => {
                  return (
                    <SmartLink
                      className="text-sm font-normal tracking-tight text-onSurfaceDim no-underline"
                      to={resolveHref(link.link)}
                      key={i}
                    >
                      {link.link?.label}
                    </SmartLink>
                  )
                })}
                <p className="body1 text-onSurfaceVariant">{copyrightText}</p>
              </div>
              <div className="flex flex-col gap-100 text-onSurfaceDim desktop:w-1/2">
                {disclaimers?.map((disclaimer, index) => {
                  return <Disclaimer key={index} {...disclaimer} />
                })}
              </div>
            </div>
          </div>
        </div>
      </Gutter>
    </footer>
  )
}
