import { useEffect, useState } from 'react'

import { FragmentOf } from '../../../lib/cms.js'
import { Gutter } from '../../Gutter/index.jsx'
import { HeaderDisplay } from '../index.js'
import { HorizontalMenu } from './HorizontalMenu.js'
import { headerNavbarFragment } from './menu-fragment.js'
import { VerticalMenu } from './VerticalMenu.js'

interface HeaderProps {
  menuItems: FragmentOf<typeof headerNavbarFragment>[]
  headerDisplay: HeaderDisplay
}

const useNavbarScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return {
    isScrolled,
  }
}

export type NavbarDisplay = 'transparent' | 'solid'

export const NavbarLayout = ({ menuItems, headerDisplay = 'solid' }: HeaderProps) => {
  const { isScrolled } = useNavbarScroll()

  const display = headerDisplay === 'solid' || isScrolled ? 'solid' : 'transparent'
  return (
    <div className={`${display === 'solid' ? 'bg-white' : 'bg-transparent'} transition-all) fixed z-[2000] w-full`}>
      <Gutter>
        <div className="block md:hidden">
          <VerticalMenu menuItems={menuItems} display={display} />
        </div>

        <div className="hidden md:block">
          <HorizontalMenu menuItems={menuItems} display={display} />
        </div>
      </Gutter>
    </div>
  )
}
