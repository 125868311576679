import { HEADER_QUERY } from '../../_graphql/globals.js'
import { NavbarLayout } from './Navbar/index.js'
import { cmsCustomHeaderFragment } from '../../_graphql/customHeader.js'
import { FragmentOf, ResultOf, readFragment } from '../../lib/cms.js'

export type HeaderDisplay = 'transparent' | 'solid' | undefined | null

export const Header = (props: {
  header: ResultOf<typeof HEADER_QUERY>['CmsHeader']
  customHeader?: FragmentOf<typeof cmsCustomHeaderFragment> | null
}) => {
  const customHeader = props.customHeader ? readFragment(cmsCustomHeaderFragment, props.customHeader) : null

  if (customHeader?.isHidden) {
    return null
  }
  return (
    <header className="relative">
      <NavbarLayout menuItems={props.header?.nav ?? []} headerDisplay={customHeader?.headerDisplay ?? 'solid'} />
    </header>
  )
}
