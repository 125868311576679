import { graphql } from '../lib/cms.js'

export const CmsMediaFragment = graphql(/* GraphQL */ `
  fragment CmsMediaFragment on CmsMedia @_unmask {
    mimeType
    filename
    width
    height
    alt
    url
    sizes {
      card {
        filename
        url
      }
      portrait {
        filename
        url
      }
      square {
        filename
        url
      }
      feature {
        filename
        url
      }
      squareThumbnail {
        filename
        url
      }
      banner {
        filename
        url
      }
      largeHero {
        filename
        url
      }
      smallAutoHeight {
        filename
        url
      }
      largeAutoHeight {
        filename
        url
      }
    }
  }
`)
