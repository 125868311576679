import { Button } from '@navinc/base-react-components/wayfinder'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'

import { resolveHref } from '@/app/lib/resolveHref.js'

import { FragmentOf, readFragment } from '../../../lib/cms.js'
import { SmartLink } from '../../SmartLink/SmartLink.jsx'
import classes from './horizontal-menu.module.scss'
import { NavbarDisplay } from './index.js'
import { ListItem } from './ListItem.jsx'
import { LinkedLogo } from './Logo.js'
import { headerNavbarFragment } from './menu-fragment.js'

interface HorizontalMenuProps {
  menuItems: FragmentOf<typeof headerNavbarFragment>[]
  display?: NavbarDisplay
}

const HorizontalMenuStaticLink = ({
  display,
  testid,
  href,
  children,
}: {
  display: NavbarDisplay
  testid: string
  href: string
  children: string
}) => (
  <div className={`${display === 'solid' ? 'text-onSurface' : 'text-inverseOnSurface'} inline-flex`}>
    <Button variant="plain" asChild className="p-100" color="inherit">
      <SmartLink to={href} style={{ color: 'inherit' }} data-testid={testid}>
        {children}
      </SmartLink>
    </Button>
  </div>
)

const CaretDown = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99987 9.96676C7.91098 9.96676 7.82765 9.95288 7.74987 9.9251C7.67209 9.89732 7.59987 9.8501 7.5332 9.78343L4.46654 6.71676C4.34431 6.59454 4.2832 6.43899 4.2832 6.2501C4.2832 6.06121 4.34431 5.90565 4.46654 5.78343C4.58876 5.66121 4.74431 5.6001 4.9332 5.6001C5.12209 5.6001 5.27765 5.66121 5.39987 5.78343L7.99987 8.38343L10.5999 5.78343C10.7221 5.66121 10.8776 5.6001 11.0665 5.6001C11.2554 5.6001 11.411 5.66121 11.5332 5.78343C11.6554 5.90565 11.7165 6.06121 11.7165 6.2501C11.7165 6.43899 11.6554 6.59454 11.5332 6.71676L8.46654 9.78343C8.39987 9.8501 8.32765 9.89732 8.24987 9.9251C8.17209 9.95288 8.08876 9.96676 7.99987 9.96676Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const HorizontalMenu = ({ menuItems: menuItemsFragment, display = 'solid' }: HorizontalMenuProps) => {
  const menuItems = readFragment(headerNavbarFragment, menuItemsFragment) ?? []
  const linkTextColor = display === 'solid' ? 'text-onSurface' : 'text-inverseOnSurface'
  return (
    <div
      className="NavigationMenuHorizontal relative z-[2000] flex gap-300 px-[72px] py-150"
      data-testid="header:navigation:horizontal"
    >
      <LinkedLogo theme={display === 'solid' ? 'primary' : 'inverse'} size="large" />
      <NavigationMenu.Root className="NavigationMenuRoot relative z-10 flex w-screen justify-start">
        <NavigationMenu.List className="NavigationMenuList m-0 flex list-none justify-center p-50 ">
          {menuItems.map((menuItem, menuItemKey) => {
            if (menuItem.type === 'submenu') {
              return (
                <NavigationMenu.Item key={menuItemKey}>
                  <NavigationMenu.Trigger
                    className={`NavigationMenuTrigger body2-emphasized space-between no-outline flex select-none items-center gap-25 px-150 py-100 text-[15px] leading-none ${linkTextColor}`}
                  >
                    {menuItem.title}{' '}
                    <span>
                      <CaretDown />
                    </span>{' '}
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="NavigationMenuContent shadow-elevation-1 absolute left-0 top-0 w-auto rounded-300">
                    <div className="flex p-250">
                      {menuItem.submenu?.categories?.map((category, categoryKey) => {
                        return (
                          <div key={categoryKey} className="min-w-[280px]">
                            <p className="caption2-uppercase">{category.name}</p>
                            <ul>
                              {category.items?.map((submenuItem, submenuItemIndex) => {
                                return (
                                  <li key={submenuItemIndex}>
                                    <NavigationMenu.Link asChild>
                                      <ListItem
                                        title={submenuItem.title}
                                        description={submenuItem.description}
                                        link={submenuItem.link}
                                      />
                                    </NavigationMenu.Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        )
                      })}
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              )
            }

            if (menuItem.type === 'link') {
              const href = resolveHref(menuItem.link)
              return (
                <NavigationMenu.Item key={menuItemKey}>
                  <NavigationMenu.Link
                    className={`NavigationMenuLink body2-emphasized no-outline block select-none px-150 py-100 text-[15px] leading-none ${linkTextColor}`}
                    href={href}
                  >
                    {menuItem.title}
                  </NavigationMenu.Link>
                </NavigationMenu.Item>
              )
            }
            return null
          })}

          <NavigationMenu.Indicator className="NavigationMenuIndicator top-full z-10 flex h-[10px] items-end justify-center overflow-hidden" />
        </NavigationMenu.List>

        <div className={`${classes.ViewportPosition} absolute left-0 top-full flex w-full justify-start`}>
          <NavigationMenu.Viewport
            className={`${classes.NavigationMenuViewport} relative mt-[10px] w-full overflow-hidden bg-surfaceBright`}
          />
        </div>
      </NavigationMenu.Root>

      <div className="flex shrink-0 items-center gap-400">
        <HorizontalMenuStaticLink href="https://help.nav.com" display={display} testid="header:support">
          Support
        </HorizontalMenuStaticLink>

        <HorizontalMenuStaticLink href="https://app.nav.com/login" display={display} testid="header:login">
          Log in
        </HorizontalMenuStaticLink>

        <Button variant="primary" density="tight" asChild>
          <a href="https://app.nav.com/registration" data-testid="header:create-account">
            Create account
          </a>
        </Button>
      </div>
    </div>
  )
}
