import { cn } from '@navinc/base-react-components'
import { themeColors } from '@navinc/base-react-components/wayfinder'
import escapeHTML from 'escape-html'
import { Fragment, ReactNode } from 'react'
import { Text } from 'slate'

import { CMSLink, CMSLinkProps } from '../Link/index.js'

// eslint-disable-next-line no-use-before-define -- This recursively renders child leaves
export type Children = Leaf[]

type TextAlign = 'left' | 'center' | 'right'

export type Leaf =
  | {
      type: string
      fields?: {
        [key: string]: unknown
      }
      textAlign?: TextAlign
      value?: {
        url: string
        alt: string
      }
      children?: Children
      url?: string
      [key: string]: unknown
    }
  | {
      text: string
      bold?: boolean
      code?: boolean
      italic?: boolean
      underline?: boolean
      strikethrough?: boolean
      superscript?: boolean
    }

const getTextAlignClass = (textAlign: TextAlign | undefined): string => {
  switch (textAlign) {
    case 'center':
      return 'text-center'
    case 'right':
      return 'text-right'
    case 'left':
      return 'text-left'
    default:
      return ''
  }
}

export const serialize = (children?: Children): ReactNode[] => {
  return (
    children?.map((node, i) => {
      if (Text.isText(node)) {
        const segments = escapeHTML(node.text)
        let text = <span className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: segments }} />

        if (node.bold) {
          text = (
            <strong className="font-bold" key={i}>
              {text}
            </strong>
          )
        }

        if (node.code) {
          text = <code key={i}>{text}</code>
        }

        if (node.italic) {
          text = (
            <em className="italic" key={i}>
              {text}
            </em>
          )
        }

        if (node.underline) {
          text = (
            <span style={{ textDecoration: 'underline' }} key={i}>
              {text}
            </span>
          )
        }

        if (node.strikethrough) {
          text = (
            <span style={{ textDecoration: 'line-through' }} key={i}>
              {text}
            </span>
          )
        }
        if (node.superscript) {
          text = <sup key={i}>{text}</sup>
        }

        if (node.text === '') {
          text = <br key={i} />
        }

        return <Fragment key={i}>{text}</Fragment>
      }

      const textAlignClass = getTextAlignClass(node.textAlign)

      switch (node.type) {
        case 'upload':
          return (
            <div className="w-full">
              <img src={node.value?.url} alt={node.value?.alt} />
            </div>
          )
        case 'h1':
          return (
            <h1 key={i} className={cn(textAlignClass, `display2-emphasized md:display1-emphasized mb-400`)}>
              {serialize(node.children)}
            </h1>
          )
        case 'h2':
          return (
            <h2 key={i} className={cn(textAlignClass, `display2-emphasized mb-200`)}>
              {serialize(node.children)}
            </h2>
          )
        case 'h3':
          return (
            <h3 key={i} className={cn(textAlignClass, `title1-emphasized mb-200`)}>
              {serialize(node.children)}
            </h3>
          )
        case 'h4':
          return (
            <h4 key={i} className={cn(textAlignClass, `title2-emphasized mb-150`)}>
              {serialize(node.children)}
            </h4>
          )
        case 'h5':
          return (
            <h5 key={i} className={cn(textAlignClass, `title3-emphasized mb-150`)}>
              {serialize(node.children)}
            </h5>
          )
        case 'h6':
          return (
            <h6 key={i} className={cn(textAlignClass, `headline1-emphasized mb-150`)}>
              {serialize(node.children)}
            </h6>
          )
        case 'ul':
          return (
            <ul key={i} className={cn(textAlignClass, `body1 list-disc text-left inline-flex flex-col`)}>
              {serialize(node.children)}
            </ul>
          )
        case 'ol':
          return (
            <ol key={i} className={cn(textAlignClass, `body1 list-decimal text-left inline-flex flex-col`)}>
              {serialize(node.children)}
            </ol>
          )
        case 'li':
          return (
            <li key={i} className="body1 ml-350">
              {serialize(node.children)}
            </li>
          )
        case 'label':
          return (
            <p key={i} className={cn(textAlignClass, `rich-text-label caption1-uppercase text-primary mb-250 w-full`)}>
              {serialize(node.children)}
            </p>
          )
        case 'link': {
          const linkVariant = node.fields?.variant
          return (
            <CMSLink
              key={i}
              type={node.linkType === 'internal' ? 'reference' : 'custom'}
              url={node.url}
              variant={(linkVariant ?? 'primaryPlain') as CMSLinkProps['variant']}
              reference={node.doc as any} // eslint-disable-line @typescript-eslint/no-explicit-any -- TODO determine if we can create a type for this
              newTab={Boolean(node.newTab)}
              style={{ fontSize: 'inherit' }}
              className={cn(
                !linkVariant || linkVariant === 'primaryPlain' || linkVariant === 'plain'
                  ? 'inline hover:underline leading-[inherit]'
                  : 'inline-flex my-100',
              )}
            >
              {serialize(node.children)}
            </CMSLink>
          )
        }

        case 'color':
          return (
            <span key={i} style={{ color: themeColors.light[node.color as keyof typeof themeColors.light] }}>
              {serialize(node.children)}
            </span>
          )

        default:
          return (
            <p key={i} className={cn(textAlignClass, 'w-full text-inherit')}>
              {serialize(node.children)}
            </p>
          )
      }
    }) || []
  )
}
